@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap);
.profileImage {
	width: 64px;
	height: 64px;
	cursor: pointer;
	border-radius: 50%;
}

.submitButton {
	border-bottom-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	cursor: pointer;
}

.profileDiv {
	display: flex;
	justify-content: center;
}

.profileDiv label {
	text-align: center;
}

.profileDiv .default {
	cursor: pointer;
}

.add_header {
	background-color: #fbfcfe;
	width: 100%;
	/* height: 80px; */
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.storageColumnCustomTable {
	border: 2px solid #353b48 !important;
	font-size: 30px !important;
}
.inventoryColumnCustomTable {
	border: 2px solid #353b48 !important;
	font-size: 30px !important;
}

.storageColumnCustomTable tr>td:nth-child(2),
.storageColumnCustomTable tr>th:nth-child(2),
.storageColumnCustomTable tr>td:nth-child(4),
.storageColumnCustomTable tr>th:nth-child(4),
.storageColumnCustomTable tr>td:nth-child(6),
.storageColumnCustomTable tr>th:nth-child(6),
.storageColumnCustomTable tr>td:nth-child(8),
.storageColumnCustomTable tr>th:nth-child(8),
.storageColumnCustomTable tr>td:nth-child(10),
.storageColumnCustomTable tr>th:nth-child(10) {
	border-right: 2px solid #353b48 !important;
}

.storageColumnCustomTable tr:last-child td {
	border-top: 2px solid #353b48 !important;
}

.storageColumnCustomTable tr:first-child td {
	border-top: 2px solid #353b48 !important;
}


.inventoryColumnCustomTable tr>td:nth-child(2),
.inventoryColumnCustomTable tr>th:nth-child(2),
.inventoryColumnCustomTable tr>td:nth-child(4),
.inventoryColumnCustomTable tr>th:nth-child(4),
.inventoryColumnCustomTable tr>td:nth-child(6),
.inventoryColumnCustomTable tr>th:nth-child(6){
	border-right: 2px solid #353b48 !important;
}

.inventoryColumnCustomTable tr:last-child td {
	border-top: 2px solid #353b48 !important;
}

.inventoryColumnCustomTable tr:first-child td {
	border-top: 2px solid #353b48 !important;
}

.active-text {
	background: #13806e !important;
	color: white !important;
	border-color: #13806e !important;
}

.table-cell-font {
	font-size: 17px !important;
}


/********** Add Your Global CSS Here **********/

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
	margin-bottom: 0;
	font-family: "Poppins", "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* letter-spacing: 0.15px; */
	/* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004); */
}

.ant-spin-container{
	opacity: 1 !important;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
	width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
	height: 5px !important;
}

.scrollbar-thumb {
	border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
	> .ant-popover-content
	> .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
	> .ant-popover-content
	> .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
	> .ant-popover-content
	> .ant-popover-arrow:after,
.ant-popover-placement-topRight
	> .ant-popover-content
	> .ant-popover-arrow:after {
	left: 0;
	margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir="rtl"] .ant-popover {
	text-align: right;
}

/* Ecommerce Card */
html[dir="rtl"] .isoCardInfoForm .ant-input {
	text-align: right;
}

/* Modal */
html[dir="rtl"] .has-success.has-feedback:after,
html[dir="rtl"] .has-warning.has-feedback:after,
html[dir="rtl"] .has-error.has-feedback:after,
html[dir="rtl"] .is-validating.has-feedback:after {
	left: 0;
	right: auto;
}

html[dir="rtl"] .ant-modal-close {
	right: inherit;
	left: 0;
}

html[dir="rtl"] .ant-modal-footer {
	text-align: left;
}

html[dir="rtl"] .ant-modal-footer button + button {
	margin-left: 0;
	margin-right: 8px;
}

html[dir="rtl"] .ant-confirm-body .ant-confirm-content {
	margin-right: 42px;
}

html[dir="rtl"] .ant-btn > .anticon + span,
html[dir="rtl"] .ant-btn > span + .anticon {
	margin-right: 0.5em;
}

html[dir="rtl"] .ant-btn-loading span {
	margin-left: 0;
	margin-right: 0.5em;
}

html[dir="rtl"]
	.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
	padding-left: 25px;
	padding-right: 29px;
}

html[dir="rtl"]
	.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
	.anticon {
	margin-right: -14px;
	margin-left: 0;
}

/* Confirm */
html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
	margin-left: 16px;
	margin-right: 0;
	float: right;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns {
	float: left;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns button + button {
	margin-right: 10px;
	margin-left: 0;
}

/* Message */
html[dir="rtl"] .ant-message .anticon {
	margin-left: 8px;
	margin-right: 0;
}

/* Pop Confirm */
html[dir="rtl"] .ant-popover-message-title {
	padding-right: 20px;
	padding-left: 0;
}

html[dir="rtl"] .ant-popover-buttons {
	text-align: left;
}

/* Notification */
html[dir="rtl"]
	.ant-notification-notice-closable
	.ant-notification-notice-message {
	padding-left: 24px;
	padding-right: 0;
}

html[dir="rtl"]
	.ant-notification-notice-with-icon
	.ant-notification-notice-message,
html[dir="rtl"]
	.ant-notification-notice-with-icon
	.ant-notification-notice-description {
	margin-right: 48px;
}

html[dir="rtl"] .ant-notification-notice-close {
	right: auto;
	left: 16px;
}

html[dir="rtl"] .ant-notification-notice-with-icon {
	left: 0;
}

/* Dropzone */
html[dir="rtl"] .dz-hidden-input {
	display: none;
}

.top_header {
	/* background-color: #fbfcfe; */
	width: 100%;
	height: 79px;
	padding: 1.5rem 0px;
}
.top_header p {
	color: #b9b9b9;
}
.center {
	display: flex;
	justify-content: center;
}
.clearSearch {
	cursor: pointer;
	margin-top: 2px;
	color: #40a9ff;
}
.ant-form-item-required::after {
	display: inline-block;
	margin-right: 4px;
	color: #f5222d;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: "*" !important;
}
.ant-form-item-label > label::after {
	position: relative;
	top: -0.5px;
	margin: 0 8px 0 2px;
	/* content: "*" !important; */
}
.ant-form-item-required::before {
	display: inline-block;
	margin-right: 4px;
	color: #f5222d;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: "" !important;
}
.rdw-dropdown-wrapper a {
	color: black;
}
.rdw-dropdown-wrapper a:hover {
	color: black;
}
.rdw-editor-main {
	height: 200px !important;
}
.backButton {
	width: 72px;
	height: 32px;
	background-color: #3e4052;
	outline: none;
	border: none;
	color: white;
	border-radius: 25px !important;
	margin-bottom: 5px;
	cursor: pointer;
	transition: background-color 0.25s ease-in-out;
}
.backButton i {
	font-size: 12px;
	margin-right: 5px;
}
.backButton:hover {
	background-color: #585b6e;
}
a:focus {
	text-decoration: none;
}

.imageModal {
	width: 40px !important;
	height: 40px !important;
	border-radius: 50%;
}

.imageThumb {
	height: 45px;
}

.c-btn {
	position: relative !important;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
	transition: color 0.15s, background-color 0.15s, border-color 0.15s,
		box-shadow 0.15s !important;
	cursor: pointer !important;
	border: 1px solid transparent !important;
	font-size: 0.8rem !important;
	font-weight: 500 !important;
	outline: none !important;
	display: inline-block !important;
	text-align: center !important;
	white-space: nowrap !important;
	line-height: 1.5 !important;
	padding: 0.375rem !important;
	margin-right: 7px;
	border-radius: 6px;
}
.c-danger {
	color: #fff !important;
	background-color: #ff3279 !important;
	border-color: #ff3279 !important;
}
.c-success {
	color: #fff !important;
	background-color: #3ac47d !important;
	border-color: #3ac47d !important;
}
.c-success:hover {
	color: #fff !important;
	background-color: #31a66a !important;
	border-color: #2e9d64 !important;
}
.c-info {
	color: #fff !important;
	background-color: #fab526 !important;
	border-color: #fab526 !important;
}
.c-round {
	border-radius: 3px !important;
}
.logo {
	width: 60px;
	height: 60px;
	margin: 1.2rem auto;
}

.rdw-link-modal {
	height: 300px !important;
}

.display-none {
	display: none;
}

.display-block {
	display: block;
}
.display-flex {
	display: flex;
}
.mt-10 {
	margin-top: 10px;
}
.item_card_box {
	margin: 10px;
}
.item_card {
	padding: 7px;
}
.item_card .ant-card-body {
	padding: 7px;
}

.top_header {
	margin: 0;
	padding: 0;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
	padding: 4px;
	overflow-wrap: normal;
}
.sort_title {
	color: green;
	cursor: pointer;
}

.addButton {
	border-bottom-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	float: right;
	margin: 1rem 0 1rem 1rem;
	cursor: pointer;
}

.statusButton {
	border-bottom-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	cursor: pointer;
}

/* .ant-modal-content{
    height: 400px;
} */

.statusButtonActive {
	border-bottom-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	cursor: pointer;
	background-color: #52c41a;
	border: none;
	color: #fff;
	height: 32px;
	/* width: 80px; */
	outline: none;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
	font-size: 0.8rem !important;
	font-weight: 500 !important;
	line-height: 1.5 !important;
	padding: 0.375rem 0.75rem !important;
}

.statusButtoninactive {
	border-bottom-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	cursor: pointer;
	background-color: #f5222d;
	border: none;
	color: #fff;
	height: 32px;
	/* width: 80px; */
	outline: none;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
	font-size: 0.8rem !important;
	font-weight: 500 !important;
	line-height: 1.5 !important;
	padding: 0.375rem !important;
}

.statusButtoninactive:hover {
	background-color: #f5222d;
}

.icon a {
	color: #555;
}

.icons a:hover {
	color: #555;
}

.viewAdminDetailWrapper {
	display: flex;
	justify-content: center;
}

.viewAdminContainer {
	margin: 0.9rem;
}

.viewAdminContainer .profileimg {
	margin-bottom: 1rem;
}

.viewAdminContainer .viewtitle {
	font-size: 15px;
	font-weight: bold;
	float: right;
	margin-right: 1rem;
	margin-bottom: 1rem;
}

.viewAdminContainer .viewdata {
	font-size: 15px;
	vertical-align: middle;
	margin-bottom: 1em;
}

.viewAdminContainer .viewAdvertisingData {
	font-size: 15px;
	line-height: 64px;
	vertical-align: middle;
	margin-bottom: 1em;
}

.text-right {
	text-align: right;
}

.disabled-button {
	cursor: not-allowed;
	pointer-events: none;
	/*Button disabled - CSS color class*/
	color: #ffffff;
	background-color: #c0c0c0;
}

.ant-spin-nested-loading {
	width: 100%;
}

