.profileImage {
	width: 64px;
	height: 64px;
	cursor: pointer;
	border-radius: 50%;
}

.submitButton {
	border-bottom-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	cursor: pointer;
}

.profileDiv {
	display: flex;
	justify-content: center;
}

.profileDiv label {
	text-align: center;
}

.profileDiv .default {
	cursor: pointer;
}

.add_header {
	background-color: #fbfcfe;
	width: 100%;
	/* height: 80px; */
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.storageColumnCustomTable {
	border: 2px solid #353b48 !important;
	font-size: 30px !important;
}
.inventoryColumnCustomTable {
	border: 2px solid #353b48 !important;
	font-size: 30px !important;
}

.storageColumnCustomTable tr>td:nth-child(2),
.storageColumnCustomTable tr>th:nth-child(2),
.storageColumnCustomTable tr>td:nth-child(4),
.storageColumnCustomTable tr>th:nth-child(4),
.storageColumnCustomTable tr>td:nth-child(6),
.storageColumnCustomTable tr>th:nth-child(6),
.storageColumnCustomTable tr>td:nth-child(8),
.storageColumnCustomTable tr>th:nth-child(8),
.storageColumnCustomTable tr>td:nth-child(10),
.storageColumnCustomTable tr>th:nth-child(10) {
	border-right: 2px solid #353b48 !important;
}

.storageColumnCustomTable tr:last-child td {
	border-top: 2px solid #353b48 !important;
}

.storageColumnCustomTable tr:first-child td {
	border-top: 2px solid #353b48 !important;
}


.inventoryColumnCustomTable tr>td:nth-child(2),
.inventoryColumnCustomTable tr>th:nth-child(2),
.inventoryColumnCustomTable tr>td:nth-child(4),
.inventoryColumnCustomTable tr>th:nth-child(4),
.inventoryColumnCustomTable tr>td:nth-child(6),
.inventoryColumnCustomTable tr>th:nth-child(6){
	border-right: 2px solid #353b48 !important;
}

.inventoryColumnCustomTable tr:last-child td {
	border-top: 2px solid #353b48 !important;
}

.inventoryColumnCustomTable tr:first-child td {
	border-top: 2px solid #353b48 !important;
}

.active-text {
	background: #13806e !important;
	color: white !important;
	border-color: #13806e !important;
}

.table-cell-font {
	font-size: 17px !important;
}

