.addButton {
	border-bottom-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	float: right;
	margin: 1rem 0 1rem 1rem;
	cursor: pointer;
}

.statusButton {
	border-bottom-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	cursor: pointer;
}

/* .ant-modal-content{
    height: 400px;
} */

.statusButtonActive {
	border-bottom-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	cursor: pointer;
	background-color: #52c41a;
	border: none;
	color: #fff;
	height: 32px;
	/* width: 80px; */
	outline: none;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
	font-size: 0.8rem !important;
	font-weight: 500 !important;
	line-height: 1.5 !important;
	padding: 0.375rem 0.75rem !important;
}

.statusButtoninactive {
	border-bottom-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
	cursor: pointer;
	background-color: #f5222d;
	border: none;
	color: #fff;
	height: 32px;
	/* width: 80px; */
	outline: none;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
	font-size: 0.8rem !important;
	font-weight: 500 !important;
	line-height: 1.5 !important;
	padding: 0.375rem !important;
}

.statusButtoninactive:hover {
	background-color: #f5222d;
}

.icon a {
	color: #555;
}

.icons a:hover {
	color: #555;
}

.viewAdminDetailWrapper {
	display: flex;
	justify-content: center;
}

.viewAdminContainer {
	margin: 0.9rem;
}

.viewAdminContainer .profileimg {
	margin-bottom: 1rem;
}

.viewAdminContainer .viewtitle {
	font-size: 15px;
	font-weight: bold;
	float: right;
	margin-right: 1rem;
	margin-bottom: 1rem;
}

.viewAdminContainer .viewdata {
	font-size: 15px;
	vertical-align: middle;
	margin-bottom: 1em;
}

.viewAdminContainer .viewAdvertisingData {
	font-size: 15px;
	line-height: 64px;
	vertical-align: middle;
	margin-bottom: 1em;
}

.text-right {
	text-align: right;
}

.disabled-button {
	cursor: not-allowed;
	pointer-events: none;
	/*Button disabled - CSS color class*/
	color: #ffffff;
	background-color: #c0c0c0;
}

.ant-spin-nested-loading {
	width: 100%;
}
