.top_header {
	/* background-color: #fbfcfe; */
	width: 100%;
	height: 79px;
	padding: 1.5rem 0px;
}
.top_header p {
	color: #b9b9b9;
}
.center {
	display: flex;
	justify-content: center;
}
.clearSearch {
	cursor: pointer;
	margin-top: 2px;
	color: #40a9ff;
}
.ant-form-item-required::after {
	display: inline-block;
	margin-right: 4px;
	color: #f5222d;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: "*" !important;
}
.ant-form-item-label > label::after {
	position: relative;
	top: -0.5px;
	margin: 0 8px 0 2px;
	/* content: "*" !important; */
}
.ant-form-item-required::before {
	display: inline-block;
	margin-right: 4px;
	color: #f5222d;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: "" !important;
}
.rdw-dropdown-wrapper a {
	color: black;
}
.rdw-dropdown-wrapper a:hover {
	color: black;
}
.rdw-editor-main {
	height: 200px !important;
}
.backButton {
	width: 72px;
	height: 32px;
	background-color: #3e4052;
	outline: none;
	border: none;
	color: white;
	border-radius: 25px !important;
	margin-bottom: 5px;
	cursor: pointer;
	transition: background-color 0.25s ease-in-out;
}
.backButton i {
	font-size: 12px;
	margin-right: 5px;
}
.backButton:hover {
	background-color: #585b6e;
}
a:focus {
	text-decoration: none;
}

.imageModal {
	width: 40px !important;
	height: 40px !important;
	border-radius: 50%;
}

.imageThumb {
	height: 45px;
}

.c-btn {
	position: relative !important;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
	transition: color 0.15s, background-color 0.15s, border-color 0.15s,
		box-shadow 0.15s !important;
	cursor: pointer !important;
	border: 1px solid transparent !important;
	font-size: 0.8rem !important;
	font-weight: 500 !important;
	outline: none !important;
	display: inline-block !important;
	text-align: center !important;
	white-space: nowrap !important;
	line-height: 1.5 !important;
	padding: 0.375rem !important;
	margin-right: 7px;
	border-radius: 6px;
}
.c-danger {
	color: #fff !important;
	background-color: #ff3279 !important;
	border-color: #ff3279 !important;
}
.c-success {
	color: #fff !important;
	background-color: #3ac47d !important;
	border-color: #3ac47d !important;
}
.c-success:hover {
	color: #fff !important;
	background-color: #31a66a !important;
	border-color: #2e9d64 !important;
}
.c-info {
	color: #fff !important;
	background-color: #fab526 !important;
	border-color: #fab526 !important;
}
.c-round {
	border-radius: 3px !important;
}
.logo {
	width: 60px;
	height: 60px;
	margin: 1.2rem auto;
}

.rdw-link-modal {
	height: 300px !important;
}

.display-none {
	display: none;
}

.display-block {
	display: block;
}
.display-flex {
	display: flex;
}
.mt-10 {
	margin-top: 10px;
}
.item_card_box {
	margin: 10px;
}
.item_card {
	padding: 7px;
}
.item_card .ant-card-body {
	padding: 7px;
}

.top_header {
	margin: 0;
	padding: 0;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
	padding: 4px;
	overflow-wrap: normal;
}
.sort_title {
	color: green;
	cursor: pointer;
}
